.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
  justify-content: center;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  height: '250px'; 
  width: '250px';
  object-fit: cover; 
  transition: opacity 0.5s ease; 
}

.image-container:hover img {
  opacity: 0.3;
}

.image-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; 
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0s linear 0.5s; 
}

.image-container:hover .image-title {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
