/* .landingBG {
  width: 100%;
  height: 100%;
  opacity: 1;
  background-image: url("../../assets/landingPage.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
} */

.landingBG {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.landingBG::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/landingPage.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  opacity: 0.35;
  z-index: -1;
}
