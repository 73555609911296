.galleryPortfolio {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.image-containerPortfolio {
  position: relative;
}

.image-containerPortfolio imgPortfolio {
  height: "300px";
  width: "300px";
  object-fit: cover;
  transition: opacity 0.5s ease;
}

.image-containerPortfolio:hover imgPortfolio {
  opacity: 1;
}

.image-titlePortfolio {
  color: white;
  /* text-align: center; */
}

.image-containerPortfolio:hover .image-titlePortfolio {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.nextui-c-dWWWSv-bsLKdX-splitted-true .nextui-c-hPzDAx:where(.nextui-c-huiNHE) {
    margin: 0
}
